import React, { useCallback, useMemo, useState } from "react";
import Helmet from "react-helmet";
import { Col, Container, Row } from "react-grid-system";
import { History } from "history";
import { useParams } from "react-router-dom";
import { useScreenClass, ScreenClass } from "react-grid-system";
import styled from "styled-components";
import { convert, UrnResource } from "@ruuby/common/lib/utils/urn";
import { useSelector } from "react-redux";
import { zonedTimeToUtc } from "date-fns-tz";

import { device } from "../../../../utils";
import { IRootState } from "../../../../store";

import { config } from "../../../../config";
import { PageBackButton } from "../../molecules/back-button";
import { UseTherapist, useTherapist } from "./hooks/use-therapist";
import { UseBackButton, useBackButton } from "../../../../hooks/use-back-button";
import { TherapistDetail } from "./components/therapist-detail";
import { useHandleTreatments } from "./hooks/use-handle-treatments";
import { usePostcode } from "./hooks/use-postcode";
import { useAvailability } from "./hooks/use-availability";
import { TherapistBookingModal } from "./components/therapist-booking-modal";
import { useLoginRedirect } from "../login/hooks/use-login-redirect";
import { BookingParams, deserializeBookingParams, serializeBookingParams } from "../checkout-page/provider";

const BackButtonContainer = styled(Container)`
  display: none;
  padding-top: ${({ theme }) => theme.spacing.size32};

  @media ${device.laptop} {
    display: block;
  }
`;

interface Props {
  history: History;
  useTherapistData?: UseTherapist;
  useBackButtonData?: UseBackButton;
}

export const TherapistDetailPage = ({
  history,
  useTherapistData = useTherapist,
  useBackButtonData = useBackButton,
}: Props): JSX.Element => {
  const { therapistId, category } = useParams();
  const [ isCheckoutLoading, setIsCheckoutLoading ] = useState(false);
  const isLoggedIn = useSelector<IRootState>((state) => state.userState.isLogedIn);
  const screenClass = useScreenClass();
  const selectedTreatmentCategoryUrn = category
    ? convert(UrnResource.TREATMENT_CATEGORY, [category])
    : "";
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  const loginRedirect = useLoginRedirect();

  const isDesktop = (["lg", "xl", "xxl", "xxxl"] as ScreenClass[]).includes(
    screenClass,
  );
  const pageUrl = useMemo(() => new URL(window.location.href), [window.location.href]);

  const { isBack, handleClickBack } = useBackButtonData({ history });
  const { 
    postcode, 
    handleSelectPostcode,
    handleRemovePostcode,
    isCoveredPostcode,
    isValidPostcode,
  } = usePostcode(pageUrl);
  const { 
    therapist, 
    reviewsData, 
    error, 
    isLoading, 
    handleReload, 
  } = useTherapistData({
    therapistId,
    postcode: postcode ?? "",
  });
  const therapistDistricts = useMemo(() => 
    therapist?.districts ?? [], 
    [therapist?.districts]
  );
  const { 
    addedTreatments,
    treatmentsDuration,
    handleAddTreatment,
    handleRemoveTreatment,
  } = useHandleTreatments({ 
    therapistUrn: therapist?.urn ?? "",
    treatments: therapist?.treatments ?? [],
  });
  const {
    selectedDate,
    selectedTime,
    availability,
    handleSelectDate, 
    handleSelectTime,
    handleRemoveTime,
  } = useAvailability({
    pageUrl,
    therapistAvailability: therapist?.availability,
    treatmentsDuration: treatmentsDuration,
    lastBookingTime: therapist?.lastBookingTime,
  });

  const handleCheckoutPress = useCallback(
    async (): Promise<void> => {
      if (!postcode || !isValidPostcode(postcode) || !isCoveredPostcode(postcode, therapistDistricts)) {
        handleRemovePostcode();
        setIsModalOpen(true);
      }
      else if (!selectedDate || !selectedTime) {
        setIsModalOpen(true);
      }
      else if (therapist) {
        setIsCheckoutLoading(true);
        setIsModalOpen(false);

        const selectedTreatments = Object.values(addedTreatments);

        const bookingParams: BookingParams = {
          ...deserializeBookingParams(),
          therapistUrn: therapist.urn,
          therapistName: therapist.displayName,
          therapistWorkstation: therapist.workstation ?? 1,
          isTherapistMobile: therapist.isMobile,
          category,
          postcode,
          bookingDate: zonedTimeToUtc(
            `${selectedDate}T${selectedTime}`,
            config.dateTime.timezone,
          ).toISOString(),
          addedTreatments: selectedTreatments,
          treatmentsTotal: selectedTreatments.reduce(
            (amount, { price, quantity }) => amount + (price * quantity),
            0,
          ),
          therapistPagePath: `${pageUrl.pathname}${pageUrl.search}`,
          addressUrn: undefined,
          address: undefined,
        };

        serializeBookingParams(bookingParams);
  
        if (!isLoggedIn) {
          loginRedirect('/booking');
        }
        else {
          history.push("/booking/");
        }

        setIsCheckoutLoading(false);
      }
    },
    [
      postcode,
      therapistDistricts,
      selectedDate,
      selectedTime,
      therapist,
      addedTreatments,
      isLoggedIn,
      isValidPostcode,
      handleRemovePostcode
    ],
  );

  return (
    <Container fluid={!isDesktop} style={{ padding: "50px 0 0" }}>
      <Helmet>
        {therapist && <title>Book {therapist.displayName} - Ruuby</title>}
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
      </Helmet>

      <BackButtonContainer fluid>
        <Col>
          <Row>
            <PageBackButton
              isBack={isBack}
              onClickBack={handleClickBack}
            />
          </Row>
        </Col>
      </BackButtonContainer>

      <TherapistDetail
        therapistId={therapistId}
        therapistData={therapist}
        therapistError={error}
        isTherapistLoading={isLoading}
        isCheckoutLoading={isCheckoutLoading}
        reviewItems={reviewsData?.reviewItems ?? []}
        reviewsError={reviewsData?.error}
        areReviewsLoading={reviewsData?.isLoading}
        selectedTreatmentCategoryUrn={selectedTreatmentCategoryUrn}
        loadMoreReviews={reviewsData?.loadMore}
        onClickReload={handleReload}
        addedTreatments={addedTreatments}
        onAddTreatment={handleAddTreatment}
        onRemoveTreatment={handleRemoveTreatment}
        onCheckoutPress={handleCheckoutPress}
      />

      <TherapistBookingModal
        isShown={isModalOpen}
        postcode={postcode}
        therapistAvailability={availability}
        selectedDate={selectedDate}
        selectedTime={selectedTime}
        isValidPostcode={isValidPostcode}
        isCoveredPostcode={(postcode) => isCoveredPostcode(postcode, therapistDistricts)}
        onClose={() => setIsModalOpen(false)}
        onSelectDate={handleSelectDate}
        onSelectTime={handleSelectTime}
        onRemoveTime={handleRemoveTime}
        onSelectPostcode={handleSelectPostcode}
        onSubmit={handleCheckoutPress}
      />      
    </Container>
  );
};
