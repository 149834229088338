import { useEffect, useState } from "react";
import {
  getCreditsDeduction,
  getPromotionDeduction,
} from "@ruuby/common/lib/utils/bookings";

import { fetchUserCreditBalance } from "../../../../../../services/api/promotion";
import { useBookingFee } from "../use-booking-fee";
import {
  PaymentServiceType,
  useGetPaymentServiceType,
} from "../../../../../../hooks/use-get-payment-service-type";
import { useBookingCheckoutContext } from "../../provider";

type UseBookingTotal = () => {
  isLoading: boolean;
  subTotal: number;
  total: number;
  bookingFee: number;
  creditsDeduction?: number;
  promotionDeduction?: number;
};

export const useBookingTotal: UseBookingTotal = () => {
  const { bookingParams } = useBookingCheckoutContext();
  const paymentProviderType = useGetPaymentServiceType();
  const {
    bookingFee,
    fetchBookingFee,
    isLoading: isLoadingFee,
  } = useBookingFee();
  const [isLoading, setIsLoading] = useState(true);
  const [credits, setCredits] = useState(0);
  const [total, setTotal] = useState(0);
  const [creditsDeduction, setCreditsDeduction] = useState(0);
  const [promotionDeduction, setPromotionDeduction] = useState(0);

  useEffect(() => {
    fetchUserCreditBalance()
      .then((credits) => setCredits(credits))
      .catch((e) => {
        console.error("Cannot load user credit balance", e.message);
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    fetchBookingFee(bookingParams);
  }, [bookingParams]);

  useEffect(() => {
    const { treatmentsTotal, promo } = bookingParams;
    if (paymentProviderType === PaymentServiceType.STRIPE) {
      const promotionDeduction = getPromotionDeduction(treatmentsTotal, promo);
      const creditsDeduction = getCreditsDeduction(
        treatmentsTotal - promotionDeduction,
        credits,
      );
      const total =
        treatmentsTotal - promotionDeduction - creditsDeduction + bookingFee;

      setPromotionDeduction(promotionDeduction);
      setCreditsDeduction(creditsDeduction);
      setTotal(total);
    } else if (paymentProviderType === PaymentServiceType.BRAINTREE) {
      const creditsDeduction = getCreditsDeduction(treatmentsTotal, credits);
      const promotionDeduction = getPromotionDeduction(
        treatmentsTotal - creditsDeduction,
        promo,
      );
      const total =
        treatmentsTotal - creditsDeduction - promotionDeduction + bookingFee;

      setCreditsDeduction(creditsDeduction);
      setPromotionDeduction(promotionDeduction);
      setTotal(total);
    }
  }, [
    bookingParams.treatmentsTotal,
    bookingParams.promo,
    bookingFee,
    credits,
    paymentProviderType,
  ]);

  return {
    isLoading: isLoadingFee || isLoading || !total,
    subTotal: bookingParams.treatmentsTotal,
    total,
    bookingFee,
    creditsDeduction,
    promotionDeduction,
  };
};
