/**
 * Configuration API
 */
import { BookingRefundRules } from "@ruuby/common/lib/types/booking";
import { ApiError } from "../error-handler";
import { getHandler } from "../request-handler";

export interface IAppConfiguration {
  BOOKING_MINIMUM_FEE: number;
  BONUS_UNIT_VALUE: number;
  BONUS_BOOKINGS_PER_BONUS: number;
  BOOKING_ADMIN_FEE: number;
  NUMBER_OF_DAY_SLOTS_TO_SHOW: number;
  CANCELLATION_POLICIES: BookingRefundRules[];
  IS_BRAINTREE_ACTIVE: boolean;
}

/**
 * Fetch ruuby app configurations
 */
export async function fetchConfiguration(): Promise<IAppConfiguration> {
  const endPoint = `/configuration` as string;

  const response = await getHandler<IAppConfiguration>(endPoint, false);

  if (response.error) {
    throw new ApiError(response.error);
  }

  return response.result;
}
