import { ContactMethod, LoginFlowType } from "../components/themed-v2/pages";
import { IAddedTreatment } from "../components/themed-v2/pages/checkout-page/provider";
import { IServiceTreatment } from "../services/api/therapists";

declare global {
  // tslint:disable-next-line: interface-name
  interface Window {
    analytics: {
      page(page: string): void;
      track(name: string, payload?: object): void;
      identify(id: string, payload?: object): void;
      reset(): void;
    };
  }
}

/**
 * List of accepted analytics events
 */
export enum AnalyticsEvents {
  ADDRESS_SELECT = "Address Suggestion Selected",
  ADDRESS_TYPE = "Address Or Postcode Search Typed",
  ADDRESS_SEARCH = "Address Searched",
  ADDRESS_SEARCH_POSTCODE_AREA = "Postcode Area Searched",
  ADDRESS_SELECT_POSTCODE_AREA = "Postcode Area Selected",
  CATEGORY_SELECT = "Category Selected",
  DATE_SELECT_DATE = "Date Selected",
  DATE_SELECT_TIME = "Time Selected",
  BREADCRUMB_CLEAR_ADDRESS = "Address Selection Cleared",
  BREADCRUMB_CLEAR_CATEGORY = "Category Selection Cleared",
  BREADCRUMB_CLEAR_DATE = "Date Selection Cleared",
  BREADCRUMB_CLEAR_THERAPIST = "Therapist Selection Cleared",
  THERAPIST_SELECT = "Therapist Selected",
  TREATMENT_SELECT = "Treatment Selected",
  TREATMENT_REMOVE = "Treatment Removed",
  BOOKING_CHECKOUT = "Proceed To Checkout Clicked",
  BOOKING_CHECKOUT_FAILED = "Not Enough Time Message Displayed",
  BOOKING_SUCCESS = "Order Completed",
  BOOKING_FAIL = "Booking Failed",
  BRAINTREE_BOOKING_SUCCESS = "Braintree Order Completed",
  STRIPE_BOOKING_SUCCESS = "Stripe Order Completed",
  STRIPE_BOOKING_FAIL = "Stripe Booking Failed",
  BRAINTREE_BOOKING_FAIL = "Braintree Booking Failed",
  BOOKING_CANCEL = "Order Cancelled",
  PROMO_CODE_SET = "Coupon Entered",
  NEWSLETTER_MODAL_SUBSCRIBE = "Newsletter Subscribed Via Modal",
  NEWSLETTER_MODAL_CANCEL = "Newsletter Modal Closed",
  SEARCH_WIDGET_FOCUSED = "Search Widget Focused",
  SEARCH_WIDGET_CHANGED = "Search Widget Changed",
  SEARCH_WIDGET_SUBMIT = "Search Widget Submit",
  LOGIN_SCREEN_VALIDATION_ERROR = "Login Screen Validation Error",
  PHONE_OTP_REQUESTED = "Login Screen Phone OTP Requested",
  EMAIL_OTP_REQUESTED = "Login Screen Email OTP Requested",
  LOGIN_FLOW_COMPLETED = "Login Flow Completed",
  OTP_SCREEN_VALIDATED = "OTP Screen Code Validated",
  OTP_SCREEN_VALIDATION_ERROR = "OTP Screen Code Validation Error",
  OTP_SCREEN_CODE_ENTERED = "OTP Screen Code Entered",
  SWITZERLAND_INTEREST = "Switzerland Interest Button Clicked",
}

interface AnalyticsEventPayload<T extends AnalyticsEvents, P extends object> {
  name: T;
  payload: P;
}

interface AnalyticsEvent<T extends AnalyticsEvents> {
  name: T;
  payload?: never;
}

export type TrackAnalyticsEvent =
  | AnalyticsEventPayload<
      AnalyticsEvents.ADDRESS_SELECT,
      { addressValue: string }
    >
  | AnalyticsEventPayload<AnalyticsEvents.ADDRESS_SELECT, { udprn: number }>
  | AnalyticsEvent<AnalyticsEvents.ADDRESS_TYPE>
  | AnalyticsEventPayload<
      AnalyticsEvents.ADDRESS_SEARCH,
      {
        numberOfResults: number;
        query: string;
      }
    >
  | AnalyticsEventPayload<
      AnalyticsEvents.ADDRESS_SEARCH_POSTCODE_AREA,
      {
        numberOfResults: number;
        query: string;
      }
    >
  | AnalyticsEventPayload<
      AnalyticsEvents.ADDRESS_SELECT_POSTCODE_AREA,
      {
        area: string;
      }
    >
  | AnalyticsEventPayload<
      AnalyticsEvents.CATEGORY_SELECT,
      {
        category: any;
        nonInteraction: boolean;
      }
    >
  | AnalyticsEventPayload<AnalyticsEvents.DATE_SELECT_DATE, { date: string }>
  | AnalyticsEventPayload<AnalyticsEvents.DATE_SELECT_TIME, { time: string }>
  | AnalyticsEventPayload<
      AnalyticsEvents.BREADCRUMB_CLEAR_ADDRESS,
      { postcode: any }
    >
  | AnalyticsEventPayload<
      AnalyticsEvents.BREADCRUMB_CLEAR_CATEGORY,
      { category: any }
    >
  | AnalyticsEventPayload<AnalyticsEvents.BREADCRUMB_CLEAR_DATE, { date: any }>
  | AnalyticsEventPayload<
      AnalyticsEvents.BREADCRUMB_CLEAR_THERAPIST,
      { therapistUrn: any }
    >
  | AnalyticsEventPayload<
      AnalyticsEvents.THERAPIST_SELECT,
      { therapistUrn: string }
    >
  | AnalyticsEventPayload<
      AnalyticsEvents.TREATMENT_SELECT,
      { treatmentUrn: string }
    >
  | AnalyticsEventPayload<AnalyticsEvents.TREATMENT_REMOVE, { label: string }>
  | AnalyticsEventPayload<
      AnalyticsEvents.EMAIL_OTP_REQUESTED,
      { email: string }
    >
  | AnalyticsEventPayload<
      AnalyticsEvents.PHONE_OTP_REQUESTED,
      { phone: string }
    >
  | AnalyticsEventPayload<
      AnalyticsEvents.LOGIN_FLOW_COMPLETED,
      { userType: LoginFlowType }
    >
  | AnalyticsEventPayload<
      AnalyticsEvents.OTP_SCREEN_VALIDATED,
      {
        type: ContactMethod;
        userType: LoginFlowType;
      }
    >
  | AnalyticsEvent<AnalyticsEvents.OTP_SCREEN_CODE_ENTERED>
  | AnalyticsEventPayload<
      AnalyticsEvents.OTP_SCREEN_VALIDATION_ERROR,
      { type: ContactMethod; error: string }
    >
  | AnalyticsEvent<AnalyticsEvents.BOOKING_CHECKOUT>
  | AnalyticsEvent<AnalyticsEvents.BOOKING_CHECKOUT_FAILED>
  | AnalyticsEventPayload<
      AnalyticsEvents.LOGIN_SCREEN_VALIDATION_ERROR,
      { error: string }
    >
  | AnalyticsEvent<AnalyticsEvents.STRIPE_BOOKING_SUCCESS>
  | AnalyticsEvent<AnalyticsEvents.STRIPE_BOOKING_FAIL>
  | AnalyticsEvent<AnalyticsEvents.BRAINTREE_BOOKING_SUCCESS>
  | AnalyticsEvent<AnalyticsEvents.BRAINTREE_BOOKING_FAIL>
  | AnalyticsEventPayload<
      AnalyticsEvents.BOOKING_SUCCESS,
      {
        coupon: string | undefined;
        currency: string;
        order_id: string;
        products: {
          category: string | undefined;
          name: string;
          price: number;
          product_id: string;
          quantity: number;
        }[];
        discount: number;
        total: number;
        subtotal: number;
        revenue: number;
        tax: number;
        content_ids: string;
        content_name: string;
        num_items: number;
      }
    >
  | AnalyticsEventPayload<AnalyticsEvents.BOOKING_FAIL, { label: string }>
  | AnalyticsEventPayload<
      AnalyticsEvents.BOOKING_CANCEL,
      { bookingUrn: string }
    >
  | AnalyticsEventPayload<AnalyticsEvents.PROMO_CODE_SET, { code: string }>
  | AnalyticsEvent<AnalyticsEvents.NEWSLETTER_MODAL_SUBSCRIBE>
  | AnalyticsEvent<AnalyticsEvents.NEWSLETTER_MODAL_CANCEL>
  | AnalyticsEventPayload<
      AnalyticsEvents.SEARCH_WIDGET_SUBMIT,
      { category: string; address: string; date: string }
    >
  | AnalyticsEvent<AnalyticsEvents.NEWSLETTER_MODAL_CANCEL>
  | AnalyticsEventPayload<
      AnalyticsEvents.NEWSLETTER_MODAL_SUBSCRIBE,
      { email: string }
    >
  | AnalyticsEvent<AnalyticsEvents.SWITZERLAND_INTEREST>;

export const identify = (urn: string, user?: object): void =>
  window.analytics.identify(urn, user);

export const page = (id: string): void => window.analytics.page(id);

export const reset = (): void => window.analytics.reset();

export const track = ({ name, payload }: TrackAnalyticsEvent): void =>
  window.analytics.track(name, payload);

export const trackAddTreatment = (treatment: IServiceTreatment): void => {
  const { urn } = treatment;

  track({
    name: AnalyticsEvents.TREATMENT_SELECT,
    payload: {
      treatmentUrn: urn,
    },
  });
};

const serialiseTreatments = (
  tr: IAddedTreatment[],
  k: keyof IAddedTreatment,
): string => tr.map((t) => t[k]).join(",");

const countTreatments = (tr: IAddedTreatment[]): number =>
  tr.reduce((acc, t) => acc + t.quantity, 0);

type TrackBookingSuccess = (params: {
  analyticsEventName:
    | AnalyticsEvents.BRAINTREE_BOOKING_SUCCESS
    | AnalyticsEvents.STRIPE_BOOKING_SUCCESS;
  promoCode?: string;
  promotionDeduction?: number;
  creditsDeduction?: number;
  bookingFee: number;
  bookingUrn: string;
  category?: string;
  treatments: IAddedTreatment[];
}) => void;

export const trackBookingSuccess: TrackBookingSuccess = ({
  analyticsEventName,
  promoCode,
  promotionDeduction = 0,
  creditsDeduction = 0,
  bookingFee,
  bookingUrn,
  treatments,
  category,
}) => {
  const products = treatments.map(({ name, price, urn, quantity }) => ({
    category,
    name,
    price,
    product_id: urn,
    quantity,
  }));

  const gmv = treatments.reduce(
    (acc, t) => acc + t.price * t.quantity,
    bookingFee,
  );
  const netGmv = gmv / 1.2;
  const netPromoCodeAmount = promotionDeduction / 1.2;

  ///I don`t know the business logic. Ask if this is the right approach!
  const netCreditsDeduction = creditsDeduction / 1.2;

  const round = (n: number) => Number(n.toFixed(2));

  track({
    name: analyticsEventName,
  });

  track({
    name: AnalyticsEvents.BOOKING_SUCCESS,
    payload: {
      coupon: promoCode,
      currency: "GBP",
      order_id: bookingUrn,
      products,
      discount: round(netPromoCodeAmount + netCreditsDeduction),
      total: round(gmv - promotionDeduction - creditsDeduction),
      subtotal: round(netGmv - netPromoCodeAmount - netCreditsDeduction),
      // Subtotal ($) with shipping and taxes added in. Note that our Google Analytics Ecommerce destination accepts total or revenue, but not both.
      // For better flexibility and total control over tracking, we let you decide how to calculate how coupons and discounts are applied
      revenue: round(netGmv),
      tax: round(gmv - netGmv),
      content_ids: serialiseTreatments(treatments, "urn"),
      content_name: serialiseTreatments(treatments, "name"),
      num_items: countTreatments(treatments),
    },
  });
};

type TrackBookingFailure = (params: {
  analyticsEventName:
    | AnalyticsEvents.BRAINTREE_BOOKING_FAIL
    | AnalyticsEvents.STRIPE_BOOKING_FAIL;
  message: string;
}) => void;

export const trackBookingFailure: TrackBookingFailure = ({
  analyticsEventName,
  message,
}) => {
  track({
    name: AnalyticsEvents.BOOKING_FAIL,
    payload: {
      label: message,
    },
  });

  track({
    name: analyticsEventName,
  });
};
