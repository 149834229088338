import React from "react";
import { useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

import { useRegisterCustomer } from "../../hooks/use-register-customer";
import { Register } from "../../components/templates/register";
import { ContactMethodType } from "../..";

export interface RegisterPageInputs {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  phoneNumber: string;
  agreesToEmailMarketing: boolean;
}

interface RegisterPageProps {
  contactMethod: ContactMethodType;
  onCompleteLogin: () => void;
}

export const RegisterPage = ({
  contactMethod,
  onCompleteLogin,
}: RegisterPageProps): JSX.Element => {
  const { contact, token } = useParams();
  const form = useForm<RegisterPageInputs>();

  const { isLoading, registerCustomer, error } = useRegisterCustomer({
    contactToken: token,
    onSuccess: onCompleteLogin,
  });

  const onSubmit: SubmitHandler<RegisterPageInputs> = (data) => {
    const { firstName, lastName, phone, email, agreesToEmailMarketing } = data;
    registerCustomer({
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      agreesToEmailMarketing: agreesToEmailMarketing === true,
      ...(phone && { phone: phone.replace("+", "") }),
      email,
    });
  };

  return (
    <Register
      form={form}
      contactMethod={contactMethod}
      contact={contact}
      isLoading={isLoading}
      error={error}
      onSubmit={onSubmit}
    />
  );
};
