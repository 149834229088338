import React, { useMemo } from "react";
import styled from "styled-components";

import { AddedTreatment } from "../../hooks/use-handle-treatments";
import { TherapistTreatment } from "../../types";
import { config } from "../../../../../../config";
import { MinimumSumLabel } from "../minimum-sum-label";
import { device } from "../../../../../../utils";
import { FloatingCtaButton } from "../../../../molecules/floating-cta-button";

const CheckoutButtonOuterContainer = styled.div`
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 75px;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.size18};
  z-index: 101;

  @media ${device.tablet} {
    margin-top: ${({ theme }) => theme.spacing.size24};
    bottom: 50px;
  }
`;

interface Props {
  isLoading: boolean;
  onCheckout(): void;
  addedTreatments: AddedTreatment;
  treatments: TherapistTreatment[];
}

export const CheckoutButtonContainer = ({
  isLoading,
  onCheckout,
  addedTreatments,
  treatments,
}: Props): JSX.Element => {
  const hasCustomerAddedTreatment = useMemo(() => {
    return Object.keys(addedTreatments).length > 0;
  }, [addedTreatments]);

  const totalCost = useMemo(() => {
    return treatments.reduce((acc, treatment) => {
      if (addedTreatments.hasOwnProperty(treatment.treatment.urn)) {
        return (
          acc +
          treatment.merchandisingPrice.price *
            addedTreatments[treatment.treatment.urn].quantity
        );
      }
      return acc;
    }, 0);
  }, [addedTreatments, treatments]);

  return (
    <>
      {hasCustomerAddedTreatment && (
        <CheckoutButtonOuterContainer>
          {totalCost >= config.minimumCallOutFee ? (
            <FloatingCtaButton isDisabled={isLoading} onClick={onCheckout} />
          ) : (
            <MinimumSumLabel />
          )}
        </CheckoutButtonOuterContainer>
      )}
    </>
  );
};
