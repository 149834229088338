import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { ContactMethodType, EMAIL_REGEX, AuthScreenType, ContactMethod } from "../../..";

import { BlueLink } from "../../atoms/blue-link";
import { BlueText } from "../../atoms/blue-text";
import { Input } from "../../atoms/input";
import { RequestOtpButton } from "../../molecules/request-otp-button";
import { IPhoneNumberObject, InputPhone } from "../../molecules/input-phone";
import { AuthContainer } from "../../organisms/auth-container";

const SwitchSection = styled('div')`
  width: 100%;
  text-align: center;
  font-family: ${({ theme }) => theme.type.fonts.main};
`;

const GrayLink = styled(Link)`
  color: ${({ theme }) => theme.colours.textMain};
`;

interface AuthProps {
  screenType: AuthScreenType,
  contact: string,
  contactMethod: ContactMethodType,
  onChangeContact: (contact: string) => void,
}

export const Auth = ({ screenType, contact, contactMethod, onChangeContact } : AuthProps): JSX.Element => {
  const valid = contactMethod === ContactMethod.PHONE ? contact.length > 5 : contact.trim().match(EMAIL_REGEX) ;
  const title = screenType === AuthScreenType.SIGNUP ? 'Sign up' : 'Log in';
  const contactMethodUrl = contactMethod.toLowerCase();
  const screenTypeUrl = screenType.toLowerCase();

  const handleChangePhoneNumber = useCallback((phone: IPhoneNumberObject): void => {
    onChangeContact(phone.fullPhoneNumber.replace('+', ''));
  }, [onChangeContact]);
  
  return (
    <AuthContainer title={title}>
        {screenType === AuthScreenType.SIGNUP ? (
          <BlueText><BlueLink to={`/login/${contactMethodUrl}`} replace>Login</BlueLink> if you already have an account</BlueText>
        ) : (
          <BlueText><BlueLink to={`/signup/${contactMethodUrl}` } replace>Sign up</BlueLink> if you're new to Ruuby</BlueText>
        )}

        { contactMethod === ContactMethod.PHONE ? (
          <InputPhone padding={true} autoFocus={true} onNumberChange={handleChangePhoneNumber} />
        ) : (
          <Input type="email" name="contact" value={contact} required={true} onChange={(e) => onChangeContact(e.target.value)} placeholder="Email address" />
        )}

        <RequestOtpButton contact={contact} contactMethod={contactMethod} disabled={!valid}>
          {title}
        </RequestOtpButton>

        <SwitchSection>
          {contactMethod === ContactMethod.PHONE ? (
            <GrayLink to={`/${screenTypeUrl}/email`} replace>{title} with email address instead</GrayLink>
          ) : (
            <GrayLink to={`/${screenTypeUrl}/phone`} replace>{title} with phone number instead</GrayLink>
          )}
        </SwitchSection>
    </AuthContainer>
  );    
}
