import React from "react";
import styled from "styled-components";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { ContactMethod, ContactMethodType } from "../../..";
import { usePhoneOtpRequest } from "../../../hooks/use-phone-otp-request";
import { useEmailOtpRequest } from "../../../hooks/use-email-otp-request";
import { Error } from "../../atoms/error";
import { Button } from "../../atoms/button";

const StyledSection = styled('div')`
  width: 100%;
`;

interface RequestOtpButtonProps {
  contactMethod: ContactMethodType,
  contact: string,
  disabled: boolean,
  children: React.ReactNode,
}

export const RequestOtpButton = ({ contactMethod, contact, disabled, children } : RequestOtpButtonProps): JSX.Element => {
  const history = useHistory();

  const onSendOtp = useCallback((token: string) => {
    history.replace(`/auth/${contactMethod.toLowerCase()}/${contact}/${token}`);
  }, [contactMethod, contact]);

  const otpHook = useMemo(() => contactMethod === ContactMethod.PHONE ? usePhoneOtpRequest : useEmailOtpRequest, [contactMethod]);

  const {
    isLoading: isOtpLoading,
    initiateOtpRequest: initiateOtpRequest,
    error: otpError,
  } = otpHook({ onSuccess: onSendOtp });

  const requestNewOtp = useCallback(() => {
    initiateOtpRequest(contact);
  }, [contact]);

  return (
    <StyledSection>
      {otpError && (
        <Error capitalize>{otpError.replaceAll('_', ' ')}</Error>
      )}
      <Button size="small" onClick={requestNewOtp} disabled={disabled} isLoading={isOtpLoading}>
        {children}
      </Button>
    </StyledSection>
  )
}

RequestOtpButton.defaultProps = {
  disabled: false,
};
