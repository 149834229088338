import { TherapistTierType } from "../../services/api/types/graphql";
import { Colours, theme as newTheme } from "../../theme/new";

type TierProperty = {
  colour: Colours;
  label: string;
};

type UseTierType = (params: { type: TherapistTierType }) => TierProperty;

// TODO Can we move this to @ruuby/common?
const tierTypes: Record<
  keyof Omit<typeof TherapistTierType, "HOSPITALITY" | "ON_DEMAND">,
  TierProperty | undefined
> = {
  [TherapistTierType.CLASSIC]: {
    colour: newTheme.colours.tierLabels.tiers.classic,
    label: "C",
  },
  [TherapistTierType.ELITE]: {
    colour: newTheme.colours.tierLabels.tiers.elite,
    label: "E",
  },
  [TherapistTierType.BLACK_LABEL]: {
    colour: newTheme.colours.tierLabels.tiers.blackLabel,
    label: "C",
  },
};

export const useTierType: UseTierType = ({ type }) => ({
  colour: tierTypes[type].colour,
  label: tierTypes[type].label,
});
