import React from "react";
import styled from "styled-components";

import { TierLabel } from "../../../../../atoms/tier-label";
import { TherapistTierType } from "../../../../../../../services/api/types/graphql";
import { device } from "../../../../../../../utils";

const TierListContainer = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.size14};
  margin: 0;
  padding: 0;

  @media ${device.mobileL} {
    justify-content: flex-end;
  }
`;

const TierContainer = styled.li`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.size8};
`;

const TierNameText = styled.span`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size14};
  font-weight: 500;
  color: ${({ theme }) => theme.colours.textGrey};
  margin: 0;
  text-transform: uppercase;

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size16};
  }
`;

interface Props {
  tierList: [TherapistTierType, string][];
}

export const TherapistListingTierList = ({ tierList }: Props): JSX.Element => (
  <TierListContainer>
    {tierList.map(([tier, name]) => (
      <TierContainer key={tier}>
        <TierLabel type={tier} />
        <TierNameText>{name}</TierNameText>
      </TierContainer>
    ))}
  </TierListContainer>
);
