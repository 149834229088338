import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import { PaymentElement, useElements } from "@stripe/react-stripe-js";

import { useSavedPaymentMethods } from "../../../../hooks/use-saved-payment-methods";
import { PaymentMethods } from "../payment-methods";
import { SavePaymentMethod } from "../save-payment-method";

const PaymentMethodsContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.size28};
`;

interface Props {
  showSavedPaymentMethods?: boolean;
  showSavePaymentMethod?: boolean;
  selectedPaymentMethodId?: string;
  savePaymentMethod?: boolean;
  onSavePaymentMethodToggle?: () => void;
  onSavedPaymentMethodClick?: (paymentMethodId: string | undefined) => void;
  onPaymentAllowedChange?: (isPaymentAllowed: boolean) => void;
}

export const StripePaymentForm = ({
  showSavedPaymentMethods,
  showSavePaymentMethod,
  selectedPaymentMethodId,
  savePaymentMethod,
  onSavedPaymentMethodClick,
  onSavePaymentMethodToggle,
  onPaymentAllowedChange,
}: Props): JSX.Element => {
  const { fetchPaymentMethods, paymentMethods, isLoading } = useSavedPaymentMethods();
  const [ isShownPaymentElement, setIsShownPaymentElement ] = useState(false);
  const elements = useElements();

  useEffect(() => {
    elements?.update({
      setupFutureUsage: savePaymentMethod ? "off_session" : null,
    });
  }, [savePaymentMethod, elements]);

  useEffect(() => {
    if (showSavedPaymentMethods) {
      fetchPaymentMethods();
    }
    else {
      setIsShownPaymentElement(true);
      onPaymentAllowedChange?.(true);
    }
  }, [showSavedPaymentMethods]);

  useEffect(() => {
    if (isLoading) {
      onPaymentAllowedChange?.(false);    
    }
    else {
      const showPaymentElement = !paymentMethods.length;

      setIsShownPaymentElement(showPaymentElement);
      onPaymentAllowedChange?.(showPaymentElement);        
    }
  }, [isLoading, paymentMethods])

  const handleSavedMethodClick = useCallback((paymentMethodId: string) => {
    setIsShownPaymentElement(false);
    onSavedPaymentMethodClick?.(paymentMethodId);
    onPaymentAllowedChange?.(true);
  }, []);

  const handleAddNewMethod = useCallback(() => {
    setIsShownPaymentElement(true);
    onSavedPaymentMethodClick?.(undefined);
    onPaymentAllowedChange?.(true);
  }, []);

  if (isLoading) {
    return <Skeleton count={3} />;
  }

  return (
    <>
      {Boolean(paymentMethods.length) && 
        <PaymentMethodsContainer>
          <PaymentMethods
            selectedPaymentMethodId={selectedPaymentMethodId}
            isAddMoreSelected={isShownPaymentElement}
            paymentMethods={paymentMethods}
            onClickPaymentMethod={handleSavedMethodClick}
            onClickAddNew={handleAddNewMethod}
          />
        </PaymentMethodsContainer>
      }
      {isShownPaymentElement &&
        <>
          <PaymentElement />
          {showSavePaymentMethod && 
            <SavePaymentMethod 
              checked={savePaymentMethod}
              onChange={onSavePaymentMethodToggle}
            />
          }
        </>
      }
    </>
  );
};