import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { rem } from "polished";
import styled from "styled-components";

import {
  BookingData,
  UsePaymentRequested,
  usePaymentRequested,
} from "./use-payment-requested";
import { PaymentHeader } from "./components/payment-header";
import { BookingCard } from "./components/booking-card";
import { SimpleLoader } from "../../../atoms";
import { ApiErrorMessage } from "../api-error-message";
import { getApiErrorText } from "../../../../errors";
import { BraintreeWidget } from "./components/braintree-widget";
import { PaymentSuccess } from "./components/payment-success";

const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${rem("92px")};
`;

interface IProps {
  useBookingData?: UsePaymentRequested;
}

export const PaymentRequestPage = ({
  useBookingData = usePaymentRequested,
}: IProps) => {
  const { token: paymentRequestToken } = useParams();
  const [ isPaymentCompleted, setIsPaymentCompleted ] = useState(false);

  const {
    bookingData,
    error: bookingError,
    loading: bookingLoading,
  } = useBookingData({
    paymentRequestToken,
  });

  const onPaymentComplete = useCallback(() =>
    setIsPaymentCompleted(true), [setIsPaymentCompleted]);

  const showBookingCard = useCallback(({
    bookingTime,
    therapistName,
    treatments,
    outstandingAmount,
  }: BookingData): JSX.Element => (
      <>
        <PaymentHeader
          title={!isPaymentCompleted
            ? "Secure your booking"
            : "Booking Secured"
          }
        />
        <BookingCard
          bookingTime={bookingTime}
          therapistName={therapistName}
          treatments={treatments}
          totalToPay={isPaymentCompleted ? 0 : outstandingAmount}
        />
      </>
    ), [isPaymentCompleted]);

  const showPaymentWidget = useCallback(({
    outstandingAmount,
    braintreeToken,
  }: BookingData): JSX.Element => {
    if (!isPaymentCompleted && outstandingAmount > 0) {
      return (
        <BraintreeWidget 
          outstandingAmount={outstandingAmount}
          paymentRequestToken={paymentRequestToken}
          braintreeToken={braintreeToken}
          onPaymentComplete={onPaymentComplete}
        />
      );
    }
    else if (isPaymentCompleted) {
      return (
        <PaymentSuccess
          size="big"
          text="Payment successful! Your booking is secured."
        />
      ); 
    }

    return (
      <PaymentSuccess 
        text="This booking has already been paid in full."
      />
    );     
  }, [isPaymentCompleted, onPaymentComplete, paymentRequestToken]);

  if (bookingError) {
    return (
      <PaymentContainer>
        <ApiErrorMessage
          title="Getting booking data failed"
          text={getApiErrorText(bookingError)}
        />
      </PaymentContainer>
    );
  }
  else if (bookingLoading) {
    return (
      <PaymentContainer>
        <SimpleLoader />
      </PaymentContainer>
    )
  }
  else if (bookingData) {
    return (
      <PaymentContainer>
        {showBookingCard(bookingData)}
        {showPaymentWidget(bookingData)}
      </PaymentContainer>
    )
  }

  return null;
};
