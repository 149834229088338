import React from "react";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { rem } from "polished";
import styled from "styled-components";

import { config } from "../../../../../../config";
import { theme } from "../../../../../../theme/new";
import { formatPrice } from "../../../../../../utils/format-price-for-display";
import { Card } from "../../../../atoms";
import { IconDate, IconPerson } from "../../../../../atoms";
import { device } from "../../../../../../utils";
import { RequestedBookingTreatment } from "../../use-payment-requested";

const PaymentCardContainer = styled(Card)`
  padding: ${({ theme }) =>
    `${theme.spacing.size12} ${theme.spacing.size18} ${theme.spacing.size18} ${theme.spacing.size12}`};
  background-color: ${({ theme }) => theme.colours.background.white};
  align-self: stretch;
  margin-left: ${({ theme }) => theme.spacing.size18};
  margin-right: ${({ theme }) => theme.spacing.size18};
  box-shadow: ${({ theme }) => theme.shadows.paymentPanel};

  @media ${device.mobileL} {
    width: 100%;
    align-self: auto;
    max-width: ${rem("335px")};
  }
`;

const HorizontalLine = styled.hr`
  position: relative;
  width: calc(100% + 30px);
  left: -12px;
  border: 0;
  margin-top: ${({ theme }) => theme.spacing.size12};
  margin-bottom: ${({ theme }) => theme.spacing.size12};
  border-bottom: 1px solid ${({ theme }) => theme.colours.inputBorderColor};
`;

const PaymentText = styled.span`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size16};
  font-weight: 400;
  color: ${({ theme }) => theme.colours.sectionPanel.text};
`;

const ItemGroup = styled.div`
  display: flex;
  align-items: center;
`;

const PaymentHeader = styled(PaymentText)`
  font-weight: 600;
`;

const Date = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.size8};
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.size12};
`;

const PaymentTextBold = styled(PaymentText)`
  font-weight: 600;
`;

const TreatmentGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.size8};
`;

const TreatmentTextGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.spacing.size12};
`;

const TreatmentDuration = styled(PaymentText)`
  margin-top: ${({ theme }) => theme.spacing.size4};
`;

const SummaryGroup = styled(ItemGroup)`
  justify-content: space-between;
`;

interface IProps {
  bookingTime: Date;
  therapistName: string;
  treatments: RequestedBookingTreatment[];
  totalToPay: number;
}

export const BookingCard = ({
  bookingTime,
  therapistName,
  treatments,
  totalToPay,
}: IProps): JSX.Element => {
  return (
    <PaymentCardContainer>
      <PaymentHeader>Appointment details</PaymentHeader>
      <HorizontalLine />
      <Date>
        <IconContainer>
          <IconDate
            width="16"
            height="18"
            fill={theme.colours.sectionPanel.text}
          />
        </IconContainer>
        <PaymentText>
          {format(
            utcToZonedTime(bookingTime, config.dateTime.timezone),
            "E, d MMM, h:mm a",
          )}
        </PaymentText>
      </Date>
      <ItemGroup>
        <IconContainer>
          <IconPerson />
        </IconContainer>
        <PaymentText>{therapistName}</PaymentText>
      </ItemGroup>
      <HorizontalLine />
      <TreatmentGroup>
        {
          treatments.map(({ quantity, name, durationMinutes }) => (
            <ItemGroup>
              <PaymentTextBold>{quantity}x</PaymentTextBold>
              <TreatmentTextGroup>
                <PaymentTextBold>{name}</PaymentTextBold>
                <TreatmentDuration>{durationMinutes} mins</TreatmentDuration>
              </TreatmentTextGroup>
            </ItemGroup>
          ))
        }
      </TreatmentGroup>
      <HorizontalLine />
      <SummaryGroup>
        <PaymentTextBold>Total to pay</PaymentTextBold>
        <PaymentTextBold>{formatPrice(totalToPay)}</PaymentTextBold>
      </SummaryGroup>
    </PaymentCardContainer>
  );
};
