import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import { ContactMethodType, AuthScreenType } from "../..";
import { Auth } from "../../components/templates/auth";

interface AuthPageProps {
  screenType: AuthScreenType,
  contactMethod: ContactMethodType
}

export const AuthPage = ({ screenType, contactMethod }: AuthPageProps): JSX.Element => {
  const params = useParams();
  const [contact, setContact] = useState(params.contact || '');

  const handleChangeContact = useCallback((contact: string) => {
    setContact(contact);
  }, []);

  return (
    <Auth
      screenType={screenType}
      contact={contact}
      contactMethod={contactMethod}
      onChangeContact={handleChangeContact}
    />
  );
}
