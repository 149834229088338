import React from "react";
import styled from "styled-components";
import { rem } from "polished";

import { device } from "../../../../../../utils";
import { Button } from "../../../../atoms";
import { UseBraintree, useBraintree } from "../../use-braintree";
import { ApiErrorMessage } from "../../../api-error-message";

const BraintreeDropIn = styled.div`
  align-self: stretch;
  margin: ${({ theme }) =>
    `${theme.spacing.size32} ${theme.spacing.size18} ${theme.spacing.size28}`};

  @media ${device.mobileL} {
    width: 100%;
    align-self: auto;
    max-width: ${rem("400px")};
  }
`;

const PaymentButton = styled(Button)`
  width: auto;
  padding-left: ${({ theme }) => theme.spacing.size18};
  padding-right: ${({ theme }) => theme.spacing.size18};
  margin-bottom: ${({ theme }) => theme.spacing.size32};
`;

interface IProps {
  paymentRequestToken: string;
  braintreeToken: string;
  outstandingAmount: number;
  onPaymentComplete: () => void;
  useBraintreeData?: UseBraintree;
}

export const BraintreeWidget = ({
  paymentRequestToken,
  braintreeToken,
  outstandingAmount,
  onPaymentComplete,
  useBraintreeData = useBraintree,
}: IProps): JSX.Element => {

  const {
    dropinContainerRef,
    error,
    loading,
    isPaymentRequestable,
    requestPayment,
    updatePaymentMethod,
  } = useBraintreeData({
    paymentRequestToken,
    braintreeToken,
    amountToPay: outstandingAmount,
    onPaymentComplete,
  });

  if (error) {
    return (
      <>
        <ApiErrorMessage 
          title="Payment failed"
          text="We tried to charge your card but something went wrong. Please update your payment method to secure your booking."
        />
        <PaymentButton
          onClick={() => updatePaymentMethod()}
          size="small"
        >
          Update Payment Method
        </PaymentButton>
      </>
    );
  }

  return (
    <>
      <BraintreeDropIn ref={dropinContainerRef} />
      <PaymentButton
        onClick={() => requestPayment()}
        size="small"
        disabled={!isPaymentRequestable || loading}
      >
        Pay Now
      </PaymentButton>
    </>   
  );
};