import React, { useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";
import { SubmitHandler, UseFormReturn } from "react-hook-form";

import { RegisterPageInputs } from "../../../pages/register";
import { ApiError } from "../../../../../../../services/api/types/graphql";
import { ContactMethod, ContactMethodType, EMAIL_REGEX } from "../../..";
import { BlueText } from "../../atoms/blue-text";
import { BlueLink } from "../../atoms/blue-link";
import { Button } from "../../atoms/button";
import { Input } from "../../atoms/input";
import { IPhoneNumberObject, InputPhone } from "../../molecules/input-phone";
import { AuthContainer } from "../../organisms/auth-container";
import { VerifiedLabel } from "../../verified-label";

const ErrorContainer = styled("div")`
  text-align: center;
`;

const Error = styled("span")`
  color: ${({ theme }) => theme.colours.error};
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size14};
`;

const Fieldset = styled("div")<{ gap?: string }>`
  width: 100%;
  ${({ gap }) => gap && `display: flex; flex-direction: column; gap: ${gap};`}
`;

const PhoneFieldset = styled(Fieldset)`
  min-height: 26px;
`;

const VerifiedContact = styled.span`
  font-family: ${({ theme }) => theme.type.fonts.main};
  color: ${({ theme }) => theme.colours.textMain};
  font-size: ${({ theme }) => theme.type.sizes.size16};
  padding-bottom: 10px;
  height: 26px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size: 14px;
  font-family: ${({ theme }) => theme.type.fonts.main};
`;

const Switch = styled.div`
  position: relative;
  width: 75px;
  height: 24px;
  background: #b3b3b3;
  border-radius: 32px;
  padding: 9px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 35px;
    top: 50%;
    left: 1px;
    background: white;
    transform: translate(0, -50%);
  }
`;

const Span = styled.span`
  padding-right: 30px;
  line-height: 1em;
  font-size: ${({ theme }) => theme.type.sizes.size16};
`;

const Checkbox = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${Switch} {
    background: ${({ theme }) => theme.colours.textBlue};

    &:before {
      transform: translate(25px, -50%);
    }
  }
`;

const Terms = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.type.sizes.size14};
  line-height: 120%;
  letter-spacing: -0.56px;
  padding-top: 20px;
`;

const TermsLink = styled(BlueLink)`
  text-decoration: none;
`;

interface RegisterProps {
  contactMethod: ContactMethodType;
  contact: string;
  form: UseFormReturn<RegisterPageInputs>;
  error: ApiError | undefined;
  onSubmit: SubmitHandler<RegisterPageInputs>;
  isLoading: boolean;
}

export const Register = ({
  contactMethod,
  contact,
  form,
  error,
  onSubmit,
  isLoading,
}: RegisterProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;
  const contactMethodUrl = contactMethod.toLowerCase();

  useEffect(() => {
    register("phoneNumber", { required: false, minLength: 6, maxLength: 13 });
  }, []);

  const handleChangePhoneNumber = useCallback(
    (phone: IPhoneNumberObject): void => {
      if (phone.phoneNumber.length > 6) {
        form.setValue("phone", phone.fullPhoneNumber.replace("+", ""), { shouldValidate: true });
      } else {
        form.setValue("phone", "", { shouldValidate: true });
      }
    },
    [form],
  );

  const apiError = useMemo(() => {
    switch (error) {
      case ApiError.SIGNUP_EMAIL_ALREADY_IN_USE:
        return "That email address is already in use on another account";
      case ApiError.SIGNUP_PHONE_ALREADY_IN_USE:
        return "That phone number is already in use on another account";
      case ApiError.INVALID_PHONE_NUMBER:
        return "Invalid phone number";
      default:
        return error;
    }
  }, [error])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AuthContainer
        title="Sign up"
        smallPadding={true}
        error={typeof error !== "undefined" || Object.keys(errors).length > 0}
      >
        {apiError && (
          <ErrorContainer>
            <Error>{apiError}</Error>
          </ErrorContainer>
        )}

        <Fieldset>
          <Input
            placeholder="First Name"
            {...register("firstName", { required: true, maxLength: 255 })}
          />
          {errors.firstName && <Error>First name is required</Error>}
        </Fieldset>

        <Fieldset>
          <Input
            placeholder="Last Name"
            {...register("lastName", { required: true, maxLength: 255 })}
          />
          {errors.lastName && <Error>Last name is required</Error>}
        </Fieldset>

        {contactMethod === ContactMethod.PHONE ? (
          <>
            <Fieldset>
              <Input
                type="email"
                placeholder="Email Address"
                {...register("email", {
                  required: true,
                  maxLength: 255,
                  pattern: EMAIL_REGEX,
                })}
              />
              {errors.email && <Error>Email is required</Error>}
            </Fieldset>

            <Fieldset>
              +<VerifiedContact>{contact}</VerifiedContact>
              <VerifiedLabel />
              <Input type="hidden" name="phone" value="contact" />
            </Fieldset>
          </>
        ) : (
          <>
            <PhoneFieldset>
              <InputPhone onNumberChange={handleChangePhoneNumber} />
              <Input type="hidden" {...register("phone", { required: true })} />
              {errors.phone && <Error>Phone is required</Error>}
            </PhoneFieldset>

            <Fieldset>
              <VerifiedContact>{contact}</VerifiedContact>
              <VerifiedLabel />
              <Input type="hidden" name="email" value="contact" />
            </Fieldset>
          </>
        )}

        <Fieldset>
          <Label>
            <Span>
              I want to receive exclusive offers & discounts in occasional
              emails.
            </Span>
            <Checkbox
              type="checkbox"
              defaultChecked={true}
              {...register("agreesToEmailMarketing")}
            />
            <Switch />
          </Label>
        </Fieldset>

        <Fieldset gap="8px">
          <Button size="small" type="submit" isLoading={isLoading}>
            Create Account
          </Button>
          <BlueText>
            Already have an account?{" "}
            <BlueLink to={`/login/${contactMethodUrl}`}>Log in</BlueLink>
          </BlueText>
        </Fieldset>

        <Fieldset>
          <Terms>
            By continuing you agree to our
            <br />
            <TermsLink to="/terms_and_conditions" target="_blank">
              Terms and Conditions
            </TermsLink>
            &nbsp;and&nbsp;
            <TermsLink to="/privacy-policy" target="_blank">
              Privacy Policy
            </TermsLink>
          </Terms>
        </Fieldset>
      </AuthContainer>
    </form>
  );
};
