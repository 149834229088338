import * as React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

interface IProps {
  currentLocation: string;
  children?: React.ReactNode;
}

const duration = {
  enter: 400,
  exit: 200,
};

// component for page transitions
const PageTransition: React.FC<IProps> = (props) => {
  return (
    <TransitionGroup>
      <CSSTransition
        key={props.currentLocation}
        classNames="pageTransition"
        timeout={duration}
      >
        {props.children}
      </CSSTransition>
    </TransitionGroup>
  );
};

export { PageTransition };
