import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import dropin from "braintree-web-drop-in";

import { theme } from "../../../../../theme";
import {
  ApiError,
  useCompleteBookingPaymentRequestMutation,
} from "../../../../../services/api/types/graphql";

export type UseBraintree = (params: {
  paymentRequestToken: string;
  braintreeToken: string;
  amountToPay: number;
  onPaymentComplete: () => void;
}) => {
  dropinContainerRef: RefObject<HTMLDivElement>;
  error: ApiError | undefined;
  loading: boolean;
  isPaymentRequestable: boolean;
  requestPayment: () => Promise<void>;
  updatePaymentMethod: () => void;
};

export const useBraintree: UseBraintree = ({
  paymentRequestToken,
  braintreeToken,
  amountToPay,
  onPaymentComplete,
}) => {
  const [braintreeInstance, setBraintreeInstance] =
    useState<dropin.Dropin | null>(null);
  const [error, setError] = useState<ApiError>();
  const dropinContainerRef = useRef<HTMLDivElement>(null);
  const [ isPaymentRequestable, setIsPaymentRequestable ] = useState(false);
  const [ is3dsCancelled, setIs3dsCancelled ] = useState(false);

  const [completeBookingPaymentRequest, { loading }] =
    useCompleteBookingPaymentRequestMutation({
      onCompleted: ({ completeBookingPaymentRequest: data }) => {
        switch (data.__typename) {
          case "PaymentRequestComplete":
            setError(undefined);
            onPaymentComplete();
            break;
          case "RuubyGraphError": {
            if (!is3dsCancelled) {
              setError(data.error);
            }
          }
        }
      },
      onError: () => {
        if (!is3dsCancelled) {
          setError(ApiError.GENERAL_ERROR);
        }
      },
      errorPolicy: "all",
    });

  const requestPayment = useCallback(async () => {
    setIs3dsCancelled(false);

    if (braintreeInstance) {
      const { nonce } = await braintreeInstance.requestPaymentMethod({
        threeDSecure: {
          amount: amountToPay.toFixed(2),
        },
      });

      await completeBookingPaymentRequest({
        variables: {
          paymentRequestToken,
          paymentMethodNonce: nonce,
        },
      });
    }
  }, [braintreeInstance, is3dsCancelled, setIs3dsCancelled]);    

  useEffect(() => {
    if (braintreeInstance) {
      const paymentMethodRequestable = async ({ type }) => {
        setIsPaymentRequestable(true);

        if (type === "ApplePayCard") {
          await requestPayment();
        }
      };

      const noPaymentMethodRequestable = () => 
        setIsPaymentRequestable(false);

      const cancelled3dSecure = () => {
        braintreeInstance.clearSelectedPaymentMethod();
        setIs3dsCancelled(true);
      };

      braintreeInstance.on("paymentMethodRequestable", paymentMethodRequestable);
      braintreeInstance.on("noPaymentMethodRequestable", noPaymentMethodRequestable);
      braintreeInstance.on("3ds:customer-canceled", cancelled3dSecure);

      return () => {
        braintreeInstance.off("paymentMethodRequestable", paymentMethodRequestable);
        braintreeInstance.off("noPaymentMethodRequestable", noPaymentMethodRequestable);
        braintreeInstance.off("3ds:customer-canceled", cancelled3dSecure);
      }
    }
  }, [braintreeInstance, setIsPaymentRequestable, setIs3dsCancelled]);

  useEffect(() => {
    if (dropinContainerRef.current && !braintreeInstance) {
      dropin.create({
        authorization: braintreeToken,
        container: dropinContainerRef.current,
        card: {
          cardholderName: { required: true },
          overrides: {
            fields: {
              cvv: {
                placeholder: "Security Code",
                type: "password",
              },
            },
            styles: {
              input: {
                color: `${theme.color.ruubyBlack}`,
                "font-size": "14px",
                "font-weight": 400,
                height: "48px",
              },
            },
          },
        },
        applePay: {
          displayName: "Ruuby",
          paymentRequest: {
            requiredBillingContactFields: ["postalAddress"],
            total: {
              amount: amountToPay.toFixed(2),
              label: "Ruuby",
            },
            countryCode: "GB",
            currencyCode: "GBP",
            supportedNetworks: ["visa", "masterCard", "amex"],
            merchantCapabilities: ["supports3DS", "supportsCredit"],              
          },
        },
        threeDSecure: true,
        vaultManager: false,
      })
      .then((dropInInstance) =>
        setBraintreeInstance(dropInInstance)
      );
    }

    return () => {
      if (braintreeInstance) {
        setBraintreeInstance(null);
        braintreeInstance.teardown(() => {});
      }
    };
  }, [dropinContainerRef, setBraintreeInstance, braintreeInstance, braintreeToken, amountToPay]);

  const updatePaymentMethod = useCallback(() => {
    setBraintreeInstance(null);
    setIsPaymentRequestable(false);
    setError(undefined);
  }, []);

  return {
    dropinContainerRef,
    error,
    loading,
    isPaymentRequestable,
    requestPayment,
    updatePaymentMethod,
  };
};
