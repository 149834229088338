import { rem } from "polished";

export enum Colours {
  WHITE = "white",
  DARK_GREY = "#505767",
  MEDIUM_GREY = "#707378",
  MEDIUM_GREY_2 = "#757B89",
  MEDIUM_GREY_3 = "#9A9EA7",
  LIGHT_GREY = "#c3c6cf",
  LIGHTER_GREY = "#e1e3e7",
  FAINT_GREY = "#F4F4F4",
  PINK = "#f5ebe7",
  BLUE_GREY = "#e7eced",
  LIGHT_BLUE = "#6AB5D5",
  ROSE = "#e3c1ac",
  BLACK = "#000",
  ORANGE = "#F09A37",
  RED = "#A14C49",
  ROSE_GOLD = "#E9C5B3",
  DEFAULT = "#212529",
}

enum Sizes {
  _4 = "4px",
  _8 = "8px",
  _10 = "10px",
  _12 = "12px",
  _14 = "14px",
  _16 = "16px",
  _18 = "18px",
  _20 = "20px",
  _22 = "22px",
  _24 = "24px",
  _26 = "26px",
  _28 = "28px",
  _30 = "30px",
  _32 = "32px",
  _36 = "36px",
  _42 = "42px",
  _48 = "48px",
  _64 = "64px",
}

export const theme = {
  type: {
    fonts: {
      heading: "bigcaslon_romregular",
      main: "sofia-pro, sans-serif",
      numbers: "adobe-caslon-pro",
    },
    sizes: {
      size8: rem(Sizes._8),
      size10: rem(Sizes._10),
      size12: rem(Sizes._12),
      size14: rem(Sizes._14),
      size16: rem(Sizes._16),
      size18: rem(Sizes._18),
      size20: rem(Sizes._20),
      size22: rem(Sizes._22),
      size24: rem(Sizes._24),
      size26: rem(Sizes._26),
      size30: rem(Sizes._30),
      size32: rem(Sizes._32),
      size36: rem(Sizes._36),
      size42: rem(Sizes._42),
      size48: rem(Sizes._48),
    },
  },
  spacing: {
    size4: Sizes._4,
    size8: Sizes._8,
    size10: Sizes._10,
    size12: Sizes._12,
    size14: Sizes._14,
    size16: Sizes._16,
    size18: Sizes._18,
    size20: Sizes._20,
    size22: Sizes._22,
    size24: Sizes._24,
    size28: Sizes._28,
    size30: Sizes._30,
    size32: Sizes._32,
    size36: Sizes._36,
    size42: Sizes._42,
    size64: Sizes._64,
  },
  colours: {
    // Use the simple colours below
    white: Colours.WHITE,
    mediumGrey2: Colours.MEDIUM_GREY_2,
    blueGrey: Colours.BLUE_GREY,
    darkGrey: Colours.DARK_GREY,
    lightBlue: Colours.LIGHT_BLUE,

    /**
     * @deprecated use direct colours instead
     */
    ruubyPink: Colours.PINK,
    /**
     * @deprecated use direct colours instead
     */
    ruubyBlueGrey: Colours.BLUE_GREY,
    /**
     * @deprecated use direct colours instead
     */
    ruubyDarkGrey: Colours.DARK_GREY,
    /**
     * @deprecated use direct colours instead
     */
    ruubyLightGrey: Colours.LIGHT_GREY,
    /**
     * @deprecated use direct colours instead
     */
    ruubyBlack: Colours.BLACK,
    /**
     * @deprecated use direct colours instead
     */
    background: {
      /**
       * @deprecated use direct colours instead
       */
      white: Colours.WHITE,
      /**
       * @deprecated use direct colours instead
       */
      greyFaint: Colours.FAINT_GREY,
      /**
       * @deprecated use direct colours instead
       */
      greyLighter: Colours.LIGHTER_GREY,
    },
    /**
     * @deprecated use direct colours instead
     */
    textMain: Colours.DARK_GREY,
    /**
     * @deprecated use direct colours instead
     */
    textGrey: Colours.MEDIUM_GREY,
    /**
     * @deprecated use direct colours instead
     */
    textGrey3: Colours.MEDIUM_GREY_3,
    /**
     * @deprecated use direct colours instead
     */
    textLightGrey: Colours.LIGHT_GREY,
    /**
     * @deprecated use direct colours instead
     */
    textBlue: Colours.LIGHT_BLUE,
    /**
     * @deprecated use direct colours instead
     */
    textPlaceholder: Colours.MEDIUM_GREY_2,
    /**
     * @deprecated use direct colours instead
     */
    text: {
      /**
       * @deprecated use direct colours instead
       */
      main: Colours.DARK_GREY,
      /**
       * @deprecated use direct colours instead
       */
      mediumGray: Colours.MEDIUM_GREY,
      /**
       * @deprecated use direct colours instead
       */
      grey: Colours.MEDIUM_GREY_2,
      /**
       * @deprecated use direct colours instead
       */
      white: Colours.WHITE,
      /**
       * @deprecated use direct colours instead
       */
      lightGrey: Colours.LIGHT_GREY,
      /**
       * @deprecated use direct colours instead
       */
      blueGrey: Colours.BLUE_GREY,
      default: Colours.DEFAULT,
    },
    /**
     * @deprecated use direct colours instead
     */
    link: {
      /**
       * @deprecated use direct colours instead
       */
      default: Colours.DARK_GREY,
      /**
       * @deprecated use direct colours instead
       */
      hover: Colours.BLACK,
    },
    /**
     * @deprecated use direct colours instead
     */
    sectionPanel: {
      /**
       * @deprecated use direct colours instead
       */
      text: Colours.MEDIUM_GREY,
    },
    /**
     * @deprecated use direct colours instead
     */
    itemPanel: {
      /**
       * @deprecated use direct colours instead
       */
      background: Colours.BLUE_GREY,
    },
    /**
     * @deprecated use direct colours instead
     */
    buttonText: Colours.WHITE,
    /**
     * @deprecated use direct colours instead
     */
    buttonBackground: Colours.DARK_GREY,
    /**
     * @deprecated use direct colours instead
     */
    buttonDisabledText: Colours.LIGHTER_GREY,
    /**
     * @deprecated use direct colours instead
     */
    buttonDisabledBackground: Colours.LIGHT_GREY,
    /**
     * @deprecated use direct colours instead
     */
    button: {
      /**
       * @deprecated use direct colours instead
       */
      whiteButton: {
        /**
         * @deprecated use direct colours instead
         */
        background: Colours.WHITE,
        /**
         * @deprecated use direct colours instead
         */
        backgroundActive: Colours.LIGHT_GREY,
        /**
         * @deprecated use direct colours instead
         */
        text: Colours.DARK_GREY,
        /**
         * @deprecated use direct colours instead
         */
        icon: Colours.DARK_GREY,
      },
      /**
       * @deprecated use direct colours instead
       */
      greyButton: {
        /**
         * @deprecated use direct colours instead
         */
        background: Colours.BLUE_GREY,
        /**
         * @deprecated use direct colours instead
         */
        backgroundActive: Colours.DARK_GREY,
        /**
         * @deprecated use direct colours instead
         */
        backgroundHover: Colours.LIGHT_GREY,
        /**
         * @deprecated use direct colours instead
         */
        text: Colours.DARK_GREY,
        /**
         * @deprecated use direct colours instead
         */
        textActive: Colours.WHITE,
        /**
         * @deprecated use direct colours instead
         */
        textHover: Colours.DARK_GREY,
      },
    },
    /**
     * @deprecated use direct colours instead
     */
    sectionBackground: {
      /**
       * @deprecated use direct colours instead
       */
      pink: Colours.PINK,
      /**
       * @deprecated use direct colours instead
       */
      white: Colours.WHITE,
      /**
       * @deprecated use direct colours instead
       */
      blueGrey: Colours.BLUE_GREY,
      /**
       * @deprecated use direct colours instead
       */
      darkGrey: Colours.DARK_GREY,
    },
    /**
     * @deprecated use direct colours instead
     */
    icon: Colours.DARK_GREY,
    /**
     * @deprecated use direct colours instead
     */
    iconOrange: Colours.ORANGE,
    /**
     * @deprecated use direct colours instead
     */
    iconGrey: Colours.LIGHT_GREY,
    /**
     * @deprecated use direct colours instead
     */
    inputBorderColor: Colours.LIGHTER_GREY,
    /**
     * @deprecated use direct colours instead
     */
    footerBorderColor: Colours.LIGHT_GREY,
    /**
     * @deprecated use direct colours instead
     */
    error: Colours.RED,
    /**
     * @deprecated use direct colours instead
     */
    tierLabels: {
      /**
       * @deprecated use direct colours instead
       */
      text: Colours.WHITE,
      /**
       * @deprecated use direct colours instead
       */
      tiers: {
        /**
         * @deprecated use direct colours instead
         */
        classic: Colours.ROSE_GOLD,
        /**
         * @deprecated use direct colours instead
         */
        elite: Colours.DARK_GREY,
        /**
         * @deprecated use direct colours instead
         */
        blackLabel: Colours.BLACK,
      },
    },
    /**
     * @deprecated use direct colours instead
     */
    tab: {
      /**
       * @deprecated use direct colours instead
       */
      text: Colours.MEDIUM_GREY,
      /**
       * @deprecated use direct colours instead
       */
      textActive: Colours.DARK_GREY,
    },
  },
  shadows: {
    panel: "0px 2px 4px 0px rgba(0, 0, 0, 0.20);",
    paymentPanel: "0px 1px 17px rgba(0, 0, 0, 0.20);",
    therapistFilter: "0px 2px 4px 0px rgba(0, 0, 0, 0.20);",
    inputField: "0px 1px 17px 0px rgba(0, 0, 0, 0.10)",
    card: {
      main: "0px 2px 4px 0px rgba(0, 0, 0, 0.20);",
      hover: "0px 2px 12px 0px rgba(0, 0, 0, 0.30)",
    },
  },
  transition: {
    ease05: "0.5s ease",
    easeInOut02: "0.2s ease-in-out",
    easeInOut03: "0.3s ease-in-out",
    easeInOut04: "0.4s ease-in-out",
    easeInOut05: "0.5s ease-in-out",
    linear02: "0.2s linear",
    linear03: "0.3s linear",
  },
} as const;
