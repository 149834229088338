import * as H from "history";
import * as React from "react";
import { Redirect, Route, Switch } from "react-router";

import { PageTransition } from "../components/molecules";
import {
  AboutPage,
  AccountPage,
  AddressPage,
  BridalPage,
  CategoryPage,
  FaqsPage,
  FeaturedTreatmentsPage,
  JobDetailPage,
  LandingProxyPage,
  NotFoundPage,
  PrivacyPolicyPage,
  RatingPage,
  TermsAndConditionsPage,
} from "../components/pages";
import { RedirectHelper } from "../components/utils";
import {
  CategoryLandingPage,
  JobListingPage,
  HomePage,
  LoginPage,
  TherapistListingPage,
  EventsPage,
  CorporatePage,
  CheckoutPage,
  BookingConfirmationPage,
  SouthOfFrancePage,
} from "../components/themed-v2/pages";
import { MainTemplate } from "../components/templates/main";
import { PaymentRequestPage } from "../components/themed-v2/pages/payment-request-page";
import { TherapistDetailPage } from "../components/themed-v2/pages/therapist-detail-page";
import { AffiliatesPage } from "../components/themed-v2/pages/affiliates-page";
import { config } from "../config";
import { SwitzerlandPage } from "../components/themed-v2/pages/switzerland";

export const appRoutes = (currentLocation: string, history: H.History) => (
  <Switch key="switch">
    <Route exact path="/(referral-return)?">
      <PageTransition currentLocation={currentLocation}>
        <MainTemplate>
          <HomePage />
        </MainTemplate>
      </PageTransition>
    </Route>
    <Route
      path="/category"
      render={(props) => (
        <PageTransition currentLocation={currentLocation}>
          <CategoryPage path={props.match.path} />
        </PageTransition>
      )}
    />
    <Route
      exact
      path="/book"
      render={() => (
        <PageTransition currentLocation={currentLocation}>
          <TherapistListingPage />
        </PageTransition>
      )}
    />
    <Route
      exact
      path="/landing/:categoryName/:districtCode"
      render={() => (
        <PageTransition currentLocation={currentLocation}>
          <LandingProxyPage />
        </PageTransition>
      )}
    />
    <Route
      exact
      path="/mobile-beauty/:categoryName/"
      render={() => (
        <PageTransition currentLocation={currentLocation}>
          <MainTemplate>
            <CategoryLandingPage history={history} />
          </MainTemplate>
        </PageTransition>
      )}
    />
    <Route
      path="/featured-treatments/:featuredListUrn"
      render={() => (
        <PageTransition currentLocation={currentLocation}>
          <FeaturedTreatmentsPage />
        </PageTransition>
      )}
    />
    <Route path="/address" render={() => <AddressPage />} />
    <Route
      exact
      path="/book/:therapistId/:category?"
      render={() => (
        <PageTransition currentLocation={currentLocation}>
          <TherapistDetailPage history={history} />
        </PageTransition>
      )}
    />
    <Route
      path="/booking/confirmation"
      render={() => (
        <PageTransition currentLocation={currentLocation}>
          <BookingConfirmationPage />
        </PageTransition>
      )}
    />      
    <Route
      path="/booking"
      render={() => (
        <PageTransition currentLocation={currentLocation}>
          <CheckoutPage />
        </PageTransition>
      )}
    />  
    <Route
      path="/payment/:token"
      render={() => (
        <PageTransition currentLocation={currentLocation}>
          <PaymentRequestPage />
        </PageTransition>
      )}
    />
    <Route
      exact
      path="/account"
      render={() => (
        <PageTransition currentLocation={currentLocation}>
          <AccountPage />
        </PageTransition>
      )}
    />
    <Redirect exact path="/login" to="/login/phone" />
    <Route
      path="/login/:type"
      render={() => (
        <PageTransition currentLocation={currentLocation}>
          <LoginPage />
        </PageTransition>
      )}
    />
    <Route
      path={"/auth/:method/:contact/:token"}
      render={() => (
        <PageTransition currentLocation={currentLocation}>
          <LoginPage />
        </PageTransition>
      )}
    />
    <Redirect exact path="/black-label" to="/about" />
    <Route
      exact
      path="/about"
      render={() => (
        <PageTransition currentLocation={currentLocation}>
          <AboutPage />
        </PageTransition>
      )}
    />
    <Route
      exact
      path="/privacy-policy"
      render={() => (
        <PageTransition currentLocation={currentLocation}>
          <PrivacyPolicyPage />
        </PageTransition>
      )}
    />
    <Route
      exact
      path="/terms_and_conditions"
      render={() => (
        <PageTransition currentLocation={currentLocation}>
          <TermsAndConditionsPage />
        </PageTransition>
      )}
    />
    <Route
      exact
      path="/faqs"
      render={() => (
        <PageTransition currentLocation={currentLocation}>
          <FaqsPage />
        </PageTransition>
      )}
    />
    <Redirect exact path="/signup" to="/signup/phone" />
    <Route
      path="/signup/:type"
      render={() => (
        <PageTransition currentLocation={currentLocation}>
          <LoginPage />
        </PageTransition>
      )}
    />
    <Route
      exact
      path="/jobs"
      render={() => (
        <PageTransition currentLocation={currentLocation}>
          <MainTemplate>
            <JobListingPage history={history} />
          </MainTemplate>
        </PageTransition>
      )}
    />
    <Route
      exact
      path="/jobs/:id"
      render={(props) => (
        <PageTransition currentLocation={currentLocation}>
          <JobDetailPage id={props.match.params.id} />
        </PageTransition>
      )}
    />
    <Route
      exact
      path="/rate/:bookingId"
      render={(props) => (
        <PageTransition currentLocation={currentLocation}>
          <RatingPage
            bookingId={props.match.params.bookingId}
            history={history}
          />
        </PageTransition>
      )}
    />
    <Route
      path="/bridal-hair-and-makeup"
      render={() => (
        <PageTransition currentLocation={currentLocation}>
          <BridalPage />
        </PageTransition>
      )}
    />
    <Route
      path="/events"
      render={() => (
        <PageTransition currentLocation={currentLocation}>
          <EventsPage />
        </PageTransition>
      )}
    />
    <Route
      path="/corporate"
      render={() => (
        <PageTransition currentLocation={currentLocation}>
          <CorporatePage />
        </PageTransition>
      )}
    />
    <Route
      path="/south-of-france"
      render={() => (
        <PageTransition currentLocation={currentLocation}>
          <SouthOfFrancePage />
        </PageTransition>
      )}
    />
    <Route
      path="/switzerland"
      render={() => (
        <PageTransition currentLocation={currentLocation}>
          <SwitzerlandPage />
        </PageTransition>
      )}
    />
    {Boolean(config.affiliates?.joinUrl) && (
      <Route
        path="/affiliates"
        render={() => (
          <PageTransition currentLocation={currentLocation}>
            <MainTemplate>
              <AffiliatesPage />
            </MainTemplate>
          </PageTransition>
        )}
      />
    )}
    <Route
      exact
      path="/404"
      render={() => (
        <PageTransition currentLocation={currentLocation}>
          <NotFoundPage />
        </PageTransition>
      )}
    />
    <RedirectHelper />
  </Switch>
);
