const matchLocation = (location: string, check: RegExp): boolean => {
  if (location.match(check)) {
    return true;
  } else {
    return false;
  }
};

/**
 * Check specific pages for flow without main navigation
 * @param location url string
 */
export const noNavPages = (location: string): boolean => {
  const locationsToCheck =
    /\b(?:address|account|booking|payment|category|book|login|signup|auth|register|checkout)\b/gi;

  return matchLocation(location, locationsToCheck);
};

/**
 * Check if the user is on the Payment page
 * @param location url string
 * @returns boolean
 */
export const isPaymentPage = (location: string): boolean => {
  const locationsToCheck = /payment/gi;

  return matchLocation(location, locationsToCheck);
};

/**
 * Check specific pages for flow with white background
 * @param location 
 */
export const isBackgroundWhite = (location: string): boolean => {
  // TODO Update this "book" path to be the same as the one for the new Therapist page
  const locationsToCheck = /book|login|signup|auth|register|checkout|address/gi;

  return matchLocation(location, locationsToCheck);
};

/**
 * Check specific pages for flow with white background only in mobile
 * @param location 
 */
export const isBackgroundWhiteOnlyMobile = (location: string): boolean => {
  const locationsToCheck = /login|signup|auth|register/gi;

  return matchLocation(location, locationsToCheck);
};

/**
 * Check specific pages for flow with white background
 * @param location 
 */
export const isVerticallyAligned = (location: string): boolean => {
  // TODO Update this "book" path to be the same as the one for the new Therapist page
  const locationsToCheck = /login|signup|auth|register|south-of-france/gi;

  return matchLocation(location, locationsToCheck);
};
